<template>
  <div class="apply">
    <loading v-if="loading"/>
    <template v-else-if="application">
      <header class="mb-3 text-center">
        <div style="font-size: 2em;">
          <b class="text-success" v-if="application.approved">您的申请已通过</b>
          <b v-else-if="application.deleted" class="text-danger">您的申请已被拒绝</b>
          <b v-else>您的申请正在审核中</b>
        </div>
        <div v-if="application.approveTime">
          申请时间：
          <datetime :value="application.approveTime"/>
        </div>
      </header>

      <b-card title="申请表">
        <b-row>
          <b-col cols="12" sm="6">
            <b-form-group label="姓名">
              {{application.name}}
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label="手机号">
              {{application.phone}}
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label="微信号">
              {{application.supplementary.wxid}}
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label="是否可以提供客服">
              {{application.supplementary.service ? '可以提供客服' : '无法提供客服'}}
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="个人情况介绍">{{application.reason}}</b-form-group>
      </b-card>

      <div class="mt-3">
        <div v-if="application.approved">
          <b-btn block to="/my/referral" v-if="application.type === 'personal'">进入代言人首页</b-btn>
          <b-btn block href="/agent" v-else-if="application.type === 'app'">进入代理中心</b-btn>
        </div>

        <div v-else-if="application.deleted">
          <b-btn block @click="application = null">重新提交申请</b-btn>
        </div>

        <div class="text-center" v-else>
          <p>微信扫码了解审核进度</p>
          <square src="https://img.xinshu.me/resource/2c4ac263f3ad4260a838b3b992d8ea95"
                  class="mx-auto" style="max-width: 200px;"/>
        </div>
      </div>
    </template>

    <template v-else>
      <b-card title="申请表格">
        <b-form-group label="姓名">
          <b-input placeholder="您的姓名" v-model="name"/>
        </b-form-group>

        <b-form-group label="手机号">
          <b-input placeholder="您的手机号，工作人员可能会通过电话联系您" maxlength="11" v-model="phone"/>
        </b-form-group>

        <b-form-group>
          <template slot="label">
            微信号（选填）
            <b-check class="float-right" v-model="wxidIsPhone">微信号同手机号</b-check>
          </template>
          <b-input placeholder="您的微信号，工作人员可能会通过微信联系您" v-model="wxid" :disabled="wxidIsPhone"/>
        </b-form-group>

        <b-form-group label="是否可以提供客服">
          <label class="mr-3">
            <input type="radio" v-model="service" :value="true" name="service"> 可以提供客服
          </label>
          <label>
            <input type="radio" v-model="service" :value="false" name="service"> 无法提供客服
          </label>
        </b-form-group>

        <b-form-group label="个人情况介绍">
          <textarea class="form-control" placeholder="粉丝数、店铺流量等情况介绍，说明您的优势，申请会更容易通过哦"
                    rows="3" v-autosize v-model="reason"></textarea>
        </b-form-group>
      </b-card>

      <b-btn block @click="onSubmit" :disabled="!canSubmit">提交申请</b-btn>
      <div class="mt-2 text-center" v-if="!canSubmit">请补全表格必填项</div>
    </template>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'apply',
  title: '申请加盟',
  data() {
    return {
      loading: true,
      service: true,
      name: '',
      phone: '',
      wxid: '',
      reason: '',
      wxidIsPhone: true,
      application: null
    }
  },
  created() {
    this.$req.get('/api/agents/applications').then(data => {
      this.application = isEmpty(data) ? null : data
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  },
  computed: {
    canSubmit() {
      return [this.name, this.phone, this.reason].every(Boolean)
    }
  },
  methods: {
    onSubmit() {
      return this.$req.post('/api/agents/applications', {
        name: this.name,
        phone: this.phone,
        wxid: this.wxidIsPhone ? this.phone : this.wxid,
        service: this.service,
        reason: this.reason
      }).then(() => {
        this.$alert.success('申请已提交，请耐心等待审核结果')
        this.$router.go(0)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .apply {
    max-width: 560px;
  }
</style>
