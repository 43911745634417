<template>
  <div class="agent-intro">
    <header>
      <square :height="isSmallScreen ? '50%' : '30%'" style="filter: blur(4px)"
              src="https://img.xinshu.me/upload/a02d748a68024595a714a23f24c10674!1920"/>
    </header>

    <section>
      <div class="container">
        <b-card>
          <div class="logo">
            <img src="@/assets/images/logo.svg" alt="" style="width: 80px;">
          </div>
          <p class="well">
            <img src="https://img.xinshu.me/upload/763c86461b174b729a76c1f2ea473372"
                 style="max-width: 320px;">
          </p>
          <div class="text">
            <p>2014年10月，心书的第一款王牌首创产品「微信书」诞生，把”5分钟一键成书”这件不可能的事，变成人人都能做到的事。</p>
            <p>自此，心书便一直以打造互联网第一自助出版平台为目标，让回忆更有温度为使命，不断努力。</p>
            <p>6年间，心书越发完善，旗下产品不断扩增，现有微博书、日记书、照片书、长文集、台历、相框、LOMO卡等等定制产品。</p>
            <p>截止今天，已经有 5389234 名用户选择心书，我们相信，未来会有更多人选择心书。</p>
          </div>
        </b-card>
      </div>
    </section>

    <section>
      <div class="title">项目背景</div>
      <div class="container">
        <b-card>
          <div class="text">
            <p>早在16年前，心书被很多宝妈奶爸以及在校的大学生所认可，联系到我们，希望能够成为心书的代言人。</p>
            <p>在这个全民创业的时代，用户希望好的产品，我们希望更多平台，我们思考再三，果断的迈出了尝试的一步，结果令人欣喜。</p>
            <p>如今随着代言人规模越来越大，现有的代言人制度，不在适合有淘宝店、微店或线下门店的合作伙伴。</p>
            <p>随即，我们开始了改革，现正式推出优化升级方案，不仅返利高，还可自定义品牌自行定价等等。</p>
            <div>如果你也热爱这个行业，想和更多志同道合的伙伴一起共创辉煌事业。那就一起来加入我们吧~</div>
          </div>
        </b-card>
      </div>
    </section>

    <section>
      <div class="title">代理权益</div>
      <div class="container">
        <b-card no-body style="overflow: hidden;">
          <square src="https://img.xinshu.me/upload/ff0ed2d0855644f99bddcfc0d106c5da"
                  height="auto"/>
        </b-card>
      </div>
    </section>

    <section>
      <div class="title">代理优势</div>
      <div class="container">
        <b-card>
          <h4 data-num="01">自定义品牌</h4>
          <p>宣传过程中，代理可以自定义品牌名称，创建一个专属自己的品牌。</p>

          <h4 data-num="02">自定义价格</h4>
          <p>在自定义品牌的同时，以心书官方售价作为参考，代理可自行修改产品定价，根据客户消费水平，提高客单价，提价上不封顶，高出部分全额归代理所有。</p>

          <h4 data-num="03">自定义销售渠道</h4>
          <p>可在淘宝、天猫、京东等全平台进行销售。</p>

          <h4 data-num="04">免囤货 免发货</h4>
          <p>代理无需自己发货，也就不需囤货，不仅避免了囤货的风险，还解决了物流的烦恼，节省了打包发货的时间。</p>

          <h4 data-num="05">专人对接 大量素材</h4>
          <p>提供专人一对一对接，并提供大量的宣传素材供您使用</p>

          <h4 data-num="06">市场大 覆盖人群广</h4>
          <p>心书的价格，是每个人都能消费得起的价格。无论是年轻小情侣，还是宝妈奶爸、热爱旅行的驴友，再或是爷爷奶奶年龄阶段的人，都是心书的目标群体。</p>
        </b-card>
      </div>
    </section>

    <section>
      <div class="title">制作流程</div>
      <div class="container">
        <b-card>
          <p>
            <img src="https://img.xinshu.me/upload/0662786f1a7642b894e9a19b62fbc666"
                 alt="" style="width: 100%; max-width: 360px;">
          </p>
          <h4 data-num="01">推广代理专属做书链接（微信、店铺、公众号、朋友圈均可推广）</h4>
          <p>申请成为心书代理，通过审核后，系统为您生成代理专属链接。您将该链接发给客户，客户即可做书。</p>
          <p>
            <img src="https://img.xinshu.me/upload/5966395f718d4b61b360c5ea4a8f2f58"
                 alt="">
          </p>

          <h4 data-num="02">客户扫码或戳链接做书（可自由修改编辑）</h4>
          <p>客户点击您的专属做书链接，选择想制作的类型开始制作。做书完成后，系统会发消息通知您，您可以引导客户编辑下单。</p>
          <p>
            <img src="https://img.xinshu.me/upload/51ce40ab20a94848adc49074595533d8"
                 alt="">
          </p>

          <h4 data-num="03">结算佣金</h4>
          <p>我们为您提供两种结算方式</p>
          <h5>①通过第三方结算（适用于有淘宝、有赞等平台店铺的情况）</h5>
          <p>用户编辑完成后，通过第三方平台（支付宝、微信等）转账给您，您在代理中心下单，同时查看订单具体情况。心书负责发货，同时系统将通过公众号提醒您订单进度。</p>
          <h5>②通过心书结算（适用于没有平台店铺的情况）</h5>
          <p>用户编辑完成后直接下单付款给心书，您可在代理中心提现，并查看订单及佣金情况。心书负责发货，同时系统将通过公众号提醒您订单进度。</p>
          <p>
            <img src="https://img.xinshu.me/upload/a11c6abf1737408cab6a0b5689fee6fc"
                 alt="">
          </p>
        </b-card>
      </div>
    </section>

    <section v-if="!isSmallScreen">
      <div class="container pb-5">
        <div class="btn-area">
          <b-btn block to="/my/agent/apply" variant="primary">
            <div>
              <fa icon="paper-plane"/>
            </div>
            点击前往申请
          </b-btn>
        </div>
      </div>
    </section>

    <bottom-bar nav>
      <template slot="navs">
        <b-nav-item class="primary" to="/my/agent/apply">
          <fa class="icon" icon="paper-plane"/>
          前往申请
        </b-nav-item>
      </template>
    </bottom-bar>
  </div>
</template>

<script>
export default {
  name: 'agentIntro',
  title: '加盟心书'
}
</script>

<style lang="scss" scoped>
.agent-intro {
  max-width: 100%;
  padding: 0;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
    .btn-block {
      font-size: 18px;
    }
  }
}

header {
  margin-bottom: -6rem;
}

.well {
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 2rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  img {
    height: 48px;
  }
}

.logo {
  margin-top: -6rem;

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 8px;
  }
}

section {
  .title {
    text-align: center;
    font-size: 18px;
    color: #fff;
    height: 2em;
    line-height: 2em;
    background-image: url(https://img.xinshu.me/upload/ddeee093a6f74d67bf6cfeb44c859801);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    position: relative;
    top: 1.2em;
    z-index: 1;
  }

  .subtitle {
    text-align: center;
  }

  .container {
    max-width: 960px;
    padding-top: 0;
    padding-bottom: 0;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.subtitle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: -2rem;
  border-radius: 100px;
  font-size: 1.5rem;
  background-color: $primary;
  width: 12em;
  color: #fff;
  padding: 1rem 1.5rem;
}

.card {
  position: relative;
  border-radius: 20px;

  .card-body {
    padding: 2.5rem;
    padding-top: 3rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

h4[data-num] {
  line-height: 1.5;

  &:before {
    content: attr(data-num);
    border-radius: 100px;
    border-bottom-right-radius: 0;
    padding: .25em;
    color: #fff;
    line-height: 1;
    margin-right: .5em;
    background-color: $primary;
    display: inline-block;
  }
}
</style>
